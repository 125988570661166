import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Container } from '../components/styles/Container'
import { EvaluationForm } from '../components/EvaluationForm'
import secureData from '../images/undraw_secure_data_0rwp.svg'

const FreeSecurityEvaluation = () => (
  <Layout backgroundImage={secureData}>
    <SEO title="Free Security Evaluation" description="Get a free three-step guided security evaluation to assess how well you're protected against today's threats." />
    <Container boxed={true}>
      <h1>Free Security Evaluation</h1>
      <p className="hero">
        You can focus on growing your business. Let us worry about protecting
        it.
      </p>
      <p>
        We'll perform a three-step guided security evaluation with you to help
        assess how well you're protected against today's threats.
      </p>
      <ol>
        <li>
          We schedule a call to get to know you and gather basic information.
        </li>
        <li>We take a closer look with a network or systems assessment.</li>
        <li>
          We walk you through a report of our findings and recommendations.
        </li>
      </ol>
      <EvaluationForm />
    </Container>
  </Layout>
)

export default FreeSecurityEvaluation
